const SpecialJson = [
  {
    title: "Turn Your May Stay into a Lucky Getaway! - 10% Off",
    slugName: "may-madness-special-offer",
    intro:
      "<p>5 lucky guests get their stay refunded at the End of May! </p>",
    previewImage: "https://d2057z2iq79qyw.cloudfront.net/amity/special-offer-preview.jpg",
    bannerImage: "https://d2057z2iq79qyw.cloudfront.net/amity/special-offers-maymadness.jpg",
    fullDescription:
      "<p>Amity Queenstown is ready to be your home this May!</p><p>Your Dream Vacation Just Got Better!</p><p>Here’s your chance to WIN* a full refund on your booking!</p><p>Apply promo code MAYMADNESS while planning your stay with us!</p><p>5 lucky winners will be announced at the end of the month!</p><p>Book your stay in May and turn your getaway into a lucky escape!</p><p>Limited slots available</p><p><b></b></p>",
    metaTitle: "Win a Free Stay This May at Amity Queenstown | MAYMADNESS Offer",
    metaDesc:
      "Turn your Queenstown escape into a lucky getaway! Book your May stay at Amity Queenstown with promo code MAYMADNESS for a chance to win a full refund. 5 lucky guests will stay for FREE—limited slots, book now!",
    metaKeywords:
      "Amity Queenstown, Queenstown hotel offer, May hotel deal, MAYMADNESS promo, free hotel stay Queenstown, Queenstown accommodation deals, Queenstown giveaway, lucky holiday Queenstown, Queenstown stay refund",
  },
  
];

export default SpecialJson;
