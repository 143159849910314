import React from "react";
import {
  faCheck,
  faCheckCircle,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WhyBookWithUs = () => (
  <div className="whybookwithus special">
    <h3>Plan & Participate Your May Getaway!</h3>
    <ul>
      <li>
        <div>
          <FontAwesomeIcon icon={faCheck} />
        </div>
        Book your stay at Amity Queenstown during the month of May.
      </li>
      <li>
        <div>
          <FontAwesomeIcon icon={faCheck} />
        </div>
        Don’t forget to use the promo code MAYMADNESS when booking!
      </li>
      <li>
        <div>
          <FontAwesomeIcon icon={faCheck} />
        </div>
        Once your booking is complete, you’ll receive a confirmation reference.
      </li>
      <li>
        <div>
          <FontAwesomeIcon icon={faCheck} />
        </div>
        Simply enter that reference and other details in the form below to join the competition.
      </li>
      
    </ul>
  </div>
);

export default WhyBookWithUs;
