import React, { useEffect, useState } from 'react';

const TypeformEmbed = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // Only run on client after hydration
    setIsClient(true);
  }, []);

  if (!isClient) return null;

  return (
    <div style={{ width: '100%', height: '600px' }}>
      <iframe
        title="May Madness Typeform"
        src="https://form.typeform.com/to/AHcdktCl"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 'none' }}
        allow="camera; microphone; autoplay; encrypted-media;"
      />
    </div>
  );
};

export default TypeformEmbed;
