import React, { useState, useEffect } from "react";
import Meta from "../../global/Meta";
import SpecialJson from "./SpecialJson";
import { NormalLink } from "../../global/CustomLinks";
import { bookingLink } from "../../global/constants";
import WhyBookWithUs from "./WhyBookWithUs";
import TypeformEmbed from "./TypeformEmbed";

const SpecialDetail = () => {
  const path = window.location.pathname.split("/");
  const [resultItem, setData] = useState({});

  const findData = () => {
    SpecialJson.map(item => {
      if (item.slugName === path[2]) {
        setData(item);
      }
    });
    return false;
  };

  useEffect(() => {
    findData();
  });
  return (
    <div>
      {resultItem && (
        <div>
          <Meta
            title={resultItem.metaTitle}
            desc={resultItem.metaDesc}
            keywords={resultItem.metaKeywords}
          />

          <div className="header-inside special">
            <img src={resultItem.bannerImage} alt={resultItem.title} />
          </div>
          <section className="white">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <a href="/specials/"> Back</a>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h2>{resultItem.title}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: resultItem.fullDescription
                    }}
                  ></p>
                  <NormalLink
                    type="btn book-now"
                    href={ bookingLink } rel="nofollow"
                    text="Book Now & Win"
                  />
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <WhyBookWithUs />
                </div>
                <div className="col-12 col-md-6 col-lg-8">
                <TypeformEmbed />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      
    </div>
  );
};

export default SpecialDetail;
